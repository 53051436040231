'use strict';

angular.module('enervexSalesappApp').controller('AccountTerritoryRequestCtrl', function($scope, $stateParams, $parse, $state, $q, AccountTerritoriesService, Auth, User, State, County, Util, _) {
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;

	$scope.isAdmin = Auth.isAdmin;
	
	$scope.accountLocationId = $stateParams.accountLocationId;
	$scope.accountTerritoryRequestId = $stateParams.accountTerritoryRequestId;
	$scope.commentOptions = {
		target: "Account Request",
		hideWho: true
	}
	$scope.newComment = {
		notifyMembers: []
	};
	Util.setNotifyMembersType($scope.newComment, $scope.job)
	var getCurrentUser = Auth.getCurrentUser;
	$scope.currentUser = getCurrentUser();

	AccountTerritoriesService.getAccount().$promise.then(function(res){
		$scope.account = res;
	});
	AccountTerritoriesService.getAccountLocation().$promise.then(function(res){
		$scope.accountLocation = res;
	});

	if ($scope.accountTerritoryRequestId !== 'new') {
		$scope.accountTerritoryRequest = AccountTerritoriesService.getRequest();
		$scope.accountTerritoryRequestComments = AccountTerritoriesService.getComments();
	}

	var activeCounties = [];
	function getActiveCounties() {
		AccountTerritoriesService.getTerritories().$promise.then(function(territories){
			$scope.activeTerritories = territories;
			_.each(territories, function(territory){
				if (territory.county){
					activeCounties.push(territory.county);
				}
			});
			getStates()
		});
	}
	function getStates() {
		State.query({limit: 300}).$promise.then(function(states){
			$q.all(_.map(states, function(state){
				var stateId = state._id;
				return County.query({state:stateId, limit: 10000}).$promise.then(function(counties){
					state.counties = _.map(counties, function(county){
						var existing = _.find(activeCounties, function(activeCounty){
							return activeCounty._id === county._id;
						});
						county.existing = existing;
						return county;
					});
				});
			})).then(function() {
				$scope.states = states;
			});
		});
	}
	getActiveCounties()
	var requestData = {
		counties: [],
		removedCounties: []
	};

	$scope.setCountyToAdd = function(state, county){
		if(!county.addStatus){
			county.addStatus = true;
			requestData.counties.push(county);
		} else {
			county.addStatus = false;
			var index = requestData.counties.indexOf(county);
			if (index > -1){
				requestData.counties.splice(index, 1);
				if (requestData.counties.length === 0 && requestData.removedCounties.length === 0){
					$scope.showSubmit = false;
				}
			}
		}
	};

	$scope.setCountyToRemove = function(state, county) {
		if(!county.removeStatus){
			county.removeStatus = true;
			requestData.removedCounties.push(county);
		} else {
			county.removeStatus = false;
			var index = requestData.removedCounties.indexOf(county);
			if (index > -1){
				requestData.removedCounties.splice(index, 1);
				if (requestData.counties.length === 0 && requestData.removedCounties.length === 0){
					$scope.showSubmit = false;
				}
			}
		}
	}

	$scope.filterCounties = function(county) {
		if (county.addStatus === true) {
			$scope.showSubmit = true;
			return true;
		}
		return false;
	};

	$scope.filterCountiesToRemove = function(county) {
		if (county.removeStatus === true) {
			$scope.showSubmit = true;
			return true;
		}
		return false;
	};

	$scope.addAll = function(counties){
		_.each(counties, function(county){
			county.addStatus = true;
			requestData.counties.push(county);
			if (county.removeStatus === true){
				county.removeStatus = false;
			}
		});
	}

	$scope.clearSelection = function(filteredCounties, state){
		_.each(state, function(county){
			if (county.existing){
				$scope.setCountyToRemove(state, county)
			}
		});
		_.each(filteredCounties, function(county){
			county.addStatus = false;
			var index = requestData.counties.indexOf(county);
			if (index > -1){
				requestData.counties.splice(index, 1);
				if (requestData.counties.length == 0 && requestData.removedCounties.length == 0){
					$scope.showSubmit = false;
				}
			}
		});
	}

	$scope.submitRequest = function(){
		AccountTerritoriesService.newRequest(requestData).$promise.then(function(){
			$state.go('account-location', {
				accountId: $scope.accountId,
				accountLocationId: $scope.accountLocationId
			}, {
				reload: true,
				inherit: false
			})
		});
	}

	function fetchComments(){
		$scope.accountTerritoryRequestComments = AccountTerritoriesService.getComments()
	}

	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader && $scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.design.visibility);
				promises.push(DesignService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				AccountTerritoriesService.makeComment(newComment).$promise.then(function(res) {
					fetchComments();
					$scope.newComment = {
						notifyMembers:[]
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					if ($scope.uploader){
						$scope.uploader.queue = [];
					}
				});
			})
		} else {
			AccountTerritoriesService.makeComment(newComment).$promise.then(function(res) {
				if ($scope.uploader){
					$scope.uploader.queue = [];
				}
				$scope.newComment = {
					notifyMembers:[]
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	$scope.saveCommentChange = function(comment){
		AccountTerritoriesService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}

	$scope.approve_Request = function() {
		AccountTerritoriesService.approveRequest().$promise.then(function(res){
			$state.go('account-location', {
				accountId: $scope.accountId,
				accountLocationId: $scope.accountLocationId
			}, {
				reload: true,
				inherit: false
			})
		});
	}

	$scope.deny_Request = function() {
		AccountTerritoriesService.denyRequest().$promise.then(function(res){
			$state.go('account-location', {
				accountId: $scope.accountId,
				accountLocationId: $scope.accountLocationId
			}, {
				reload: true,
				inherit: false
			})
		});
	}



	// Not Implemented in Design/Mocks - holding off on html implementation
	$scope.deleteComment = function(comment){
		AccountTerritoriesService.removeComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
});
